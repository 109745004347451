import React from "react"
import { Navbar } from 'react-bootstrap'
import { Link } from "gatsby"
import sklogo from '../images/sk-logo.png'
import sklogosticky from '../images/sk-logo-sticky.png'
import styles from './blogNavBar.module.css';



const categoryNames = {
    '': "All",
    'features': "Features",
    'general': "General",
    'case-studies': "Case Studies",
    'setup': "Setup",
    'tips': "Tips",
    'announcements': "Announcements",
    'news': "News"
}

function LinkItem(category, currentCategory) {
    let style = currentCategory === category ? { color: '#fff' } : {};
    let to = category ? `/blog/category/${category}/` : "/blog/";
    return (
        <li className="nav-item nav-items">
            <Link to={to} className="nav-link" activeClassName="active" style={style}>{categoryNames[category]}</Link>
        </li>
    );
}

export default function BlogNavBar({ currentCategory }) {

    return (
        <div className={`container-fluid sk-adaptive-header ${styles.skAdaptiveHeader}` }>
            
            <Navbar collapseOnSelect expand="lg" variant="dark" className="container bg-pri-blue ">
                <Navbar.Brand className={styles.brand}>
                    <Link to="/" className={styles.home}>
                        <img className={`sk-logo ${styles.sklogo}`} src={sklogo}  alt="sk-logo" />
                        <img className={`sk-logo-sticky ${styles.sklogo}`} src={sklogosticky}  alt="sk-logo" />
                    </Link>
                    <Link to="/blog/" className={`nav-link ${styles.blogLink}`}>Blog</Link>

                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <ul className={`navbar-nav ${styles.ul}`} >
                        {LinkItem("", currentCategory)}
                        {LinkItem("features", currentCategory)}
                        {LinkItem("setup", currentCategory)}
                        {LinkItem("general", currentCategory)}
                        {LinkItem("case-studies", currentCategory)}
                        {LinkItem("tips", currentCategory)}
                        {LinkItem("news", currentCategory)}
                    </ul>
                </Navbar.Collapse>
            </Navbar>

        </ div>
    );
}
