import React, { useEffect } from "react";
import { graphql } from "gatsby";
//mport Header from "../components/Header.js";
import SheetkraftFooter from "../components/SheetkraftFooter.js";
import LetsTalk from "../components/LetsTalk";
import TagCloud from "../components/TagCloud";
import Announcements from "../components/Announcements";
import BlogNavBar from "../components/BlogNavBar";
import { Link } from "gatsby";
//import SearchApi from 'js-worker-search'
import Slide from "react-reveal/Slide";
import "../styles/main.css";
import { Helmet } from "react-helmet";
import styles from "./blog.module.css";
import BlogModal from "../components/BlogModal";

import AnchorLink from 'react-anchor-link-smooth-scroll'

function PaginationLinks({ numPages, currentPage, basePath }) {
  if (numPages === 1) return null;
  return (
    <ul className={styles.paginationLinksUl}>
      {Array.from({ length: numPages }, (_, i) => {
        let isCurrent = currentPage === i + 1;
        let style = isCurrent
          ? { color: "red", fontWeight: "bold", borderColor: "red" }
          : {};
        let to = i === 0 ? basePath : `${basePath}${i + 1}/`;
        return (
          <li className={styles.paginationLinksLi} key={i}>
            <Link to={to} style={style} className="page-link">
              {i + 1}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

export const query = graphql`
  query($postIds: [String!]!) {
    allMarkdownRemark(
      filter: { id: { in: $postIds } }
      sort: { fields: [frontmatter___published], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            published(formatString: "MMMM DD, YYYY")
            author
            tags
            author
            category
          }
          fields {
            slug
          }
          id
          excerpt(pruneLength: 100)
        }
      }
    }

    allIndexYaml(
      filter: { id: { in: $postIds } }
      sort: { fields: published, order: DESC }
    ) {
      edges {
        node {
          id
          published(formatString: "MMMM DD, YYYY")
          version
          other
          excerpt
          tags
          category
        }
      }
    }
  }
`;

function BlogPost({ node }) {
  let author = node.frontmatter.author.replace(/ /g, "-").toLowerCase();

  return (
    <div className={`grid ${styles.gridStyles}`}>
      <div className={`gridBoxes ${styles.gridBoxesStyles}`}>
        <Link to={node.fields.slug} className={styles.textDecoration}>
          <h4 className={styles.frontmatterTitle}>{node.frontmatter.title}</h4>
        </Link>
        <p className={styles.frontmatterPublished}>
          {node.frontmatter.published} by
          <Link
            to={`/blog/author/${author}/`}
            className={styles.frontmatterAuthor}
          >
            {node.frontmatter.author}
          </Link>
        </p>

        <p className={styles.excerpt}>{node.excerpt}</p>

        <Link className={styles.slug} to={node.fields.slug}>
          Continue Reading
        </Link>
      </div>

      <div className={`tagBox ${styles.tagBoxStyles}`}>
        {node.frontmatter.tags.map((tag, index) => {
          return (
            <Link
              to={"/blog/tag/" + tag + "/"}
              key={index}
              id="taglinks"
              className={
                styles.tagLinks
              } /**onClick={() => { funct(tag,data)}}**/
            >
              {tag}
            </Link>
          );
        })}
      </div>
    </div>
  );
}

function BlogAnnouncement({ node }) {
  return (
    <div className={`grid ${styles.gridStyles}`}>
      <div className={`gridBoxes ${styles.gridBoxesStyles}`}>
        <Link to={`/blog/${node.version}/`} className={styles.textDecoration}>
          <h4 className={styles.frontmatterTitle}>
            Version {node.version} has been released
          </h4>
        </Link>
        <p className={styles.frontmatterPublished}>
          {node.published} by
          <span className={styles.frontmatterAuthor}>SheetKraft Bot</span>
        </p>

        <p className={styles.excerpt}>{node.excerpt}</p>

        <Link className={styles.slug} to={`/blog/${node.version}/`}>
          Continue Reading
        </Link>

        <div className={`tagBox ${styles.tagBoxStyles}`}>
          <Link
            to={"/blog/tag/" + node.tags + "/"}
            key={node.id}
            id="taglinks"
            className={styles.tagLinks}
          >
            {node.tags}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default props => {
  let { data, pageContext } = props;
  let { currentPage, numPages, category, tag, author } = pageContext;

  //For pagination
  let basePath = category
    ? `/blog/category/${category}/`
    : tag
      ? `/blog/tag/${tag}/`
      : author
        ? `/blog/author/${author}/`
        : `/blog/`;

  useEffect(() => {
    document.addEventListener("scroll", scrollHandler);
    return () => {
      document.removeEventListener("scroll", scrollHandler);
    };
  });

  const scrollHandler = () => {
    let y = document.getElementsByClassName("sk-adaptive-header")[0];
    if (window.pageYOffset > 0) {
      y.classList.add("sk-is-sticky");
    } else {
      y.classList.remove("sk-is-sticky");
    }
  };

  const gotoContactBlog = {
    position: 'fixed',
    top: '47%',
    left: '0px',
    background: '#4472c4',
    color: '#fff',
    padding: '10px',
    transition: 'linear .2s',
    zIndex: '2',
    cursor: 'pointer'
  }

  return (
    <div >
      <Helmet>
        <meta
          name="description"
          property="og:description"
          content="SheetKraft builds high quality web-based applications using pre-designed powerful building blocks and connects to diverse sources of data across all your internal business applications"
        />
        <meta name="title" property="og:title" content="SheetKraft" />
        <meta
          name="image"
          property="og:image"
          content="https://www.sheetkraft.com/marketing-assets/images/sk-logo-sticky.png"
        />
        <meta name="author" content="SheetKraft" />
        <meta name="robots" content="noindex" />
        <meta
          name="keywords"
          content=" automation, automation anywhere, uipath, rpa, robotic process automation, automate processes, robotic automation process, rpa services, rpa software, rpa tools, blue prismno code, rapid application development,business process automation"
        />

        <title>SheetKraft - Blog</title>
      </Helmet>

      <BlogNavBar currentCategory={category} />

      <div className="container">
        <div className="row">
          <div className={`col-lg-12 bread ${styles.margin}`}></div>
        </div>
        <div className={`row ${styles.paddingTop}`}>
          <div className="col-lg-8">
            <Slide bottom>
              <div className={`grids ${styles.gridsStyles}`}>
                {[
                  ...data.allMarkdownRemark.edges.map(({ node }) => [
                    node.frontmatter.published,
                    <BlogPost node={node} key={node.id} />
                  ]),
                  ...data.allIndexYaml.edges.map(({ node }) => [
                    node.published,
                    <BlogAnnouncement node={node} key={node.id} />
                  ])
                ]
                  .sort(function (a, b) {
                    return new Date(b[0]) - new Date(a[0]);
                  })
                  .map(e => e[1])}
              </div>
              <div>
                <PaginationLinks
                  basePath={basePath}
                  currentPage={currentPage}
                  numPages={numPages}
                />
              </div>
            </Slide>
          </div>

          <div className={`col-lg-4 mobilePadding ${styles.collg4}`}>
            {category !== "announcements" && (
              <div className={styles.mb}>
                <Announcements />
              </div>
            )}
            <TagCloud currentTag={tag} basePath={basePath} />
          </div>
        </div>
      </div>

      <div className={`container-fluid ${styles.modalPosition}`}>
        <div className="row">
          <div className="col-lg-12">
            <BlogModal />
          </div>
        </div>
      </div>

      <br />
      <LetsTalk />

      <SheetkraftFooter />
      <AnchorLink href='#contactForm' className="scrolldown" id="gotoContactBlog" style={gotoContactBlog}> < i className="fa fa-phone"> </i> </AnchorLink>
    </div>
  );
};
