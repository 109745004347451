import React, { useState, useEffect } from 'react'
// import Modal from 'react-bootstrap/Modal'
// import Button from 'react-bootstrap/Button'
import styles from "./blogmodal.module.css"
import "../styles/main.css";

export default function BlogModal() {

    const subscribeStyles = {
        fontWeight: "bold"
    }

    const shouldPerventModal = () => {
        let emailCookieValue = readCookie('email');
        let modalClosed = window.sessionStorage.getItem("modalclosed") != null;
        return !!modalClosed || !!emailCookieValue;
    }

    const [show, setShow] = useState(false);
    const [showMobileButton, setShowMobileButton] = useState(false);

    const onTriggerModal = (event) => {
        if (!shouldPerventModal() && (!event.relatedTarget || !event.toElement)) {
            setShowMobileButton(false);
            handleModalShow();
        }
    }

    useEffect(() => {
        let isMobile = window.orientation !== undefined;
        setShowMobileButton(isMobile && !shouldPerventModal());
        const handleEventListener = (element, event) => {
            element.addEventListener(event, onTriggerModal);
            return () => {
                element.removeEventListener(event, onTriggerModal);
            }
        }
        if (isMobile) {
            let isMobileButton = document.getElementById('isMobile');
            return handleEventListener(isMobileButton, 'click');
        }
        else {
            return handleEventListener(window, 'mouseout');
        }
    }, []); // this should run only once, hence []

    function createCookie(key, value) {
        let d = new Date();
        d.setFullYear(d.getFullYear() + 1);
        let expiration = d.toGMTString();
        let cookie = escape(key) + "=" + escape(value) + ";expires=" + expiration + ";";
        document.cookie = cookie;
    }

    function readCookie(name) {
        let key = name + "=";
        let cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1, cookie.length);
            }
            if (cookie.indexOf(key) === 0) {
                return cookie.substring(key.length, cookie.length);
            }
        }
        return null;
    }

    let arr = [];
    function submitForm(e) {
        e.preventDefault();
        let requestBody = {};
        let emailValue = document.getElementById('email').value;
        requestBody['email'] = emailValue;
        let json = JSON.stringify(requestBody);
        arr.push(requestBody);
        let xhr = new XMLHttpRequest();
        let url = window.self.location.origin + "/api/contact/blog";
        let message = document.getElementById('message');
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                createCookie('email', emailValue);
                message.innerHTML = "Thank you for your feedback.";
                message.style.color = "green";
                message.style.display = "block";
                setTimeout(handleModalClose, 1500);
            }
            else {
                message.innerHTML = "Some error occured.";
                message.style.color = "red";
                message.style.display = "block";
            }
        }
        xhr.open("POST", url, false);
        xhr.setRequestHeader("content-type", "application/json");
        xhr.send(json);
    }

    if (typeof document === 'undefined') return null; // As a workaround - document is not available during server side rendering in Gatsby

    function closeModal() {
        window.sessionStorage["modalclosed"] = "true";
        handleModalClose();
    }

    function handleModalClose() {
        let toastStyle = document.getElementById('subscribeForm').style;
        toastStyle.bottom = -60;
        toastStyle.animationDuration = '0.5s';
        toastStyle.animationFillMode = 'forwards';
        toastStyle.animationTimingFunction = 'ease-out';
        toastStyle.animationName = 'slideout';
        setTimeout(() => setShow(false), 500);
    }

    function handleModalShow() {
        setShow(true);
        let toastStyle = document.getElementById('subscribeForm').style;
        toastStyle.animationDuration = '0.5s';
        toastStyle.animationFillMode = 'forwards';
        toastStyle.animationTimingFunction = 'ease-in';
        toastStyle.animationName = 'slidein';
    }

    return (
        <>
            <button className={`btn btn-primary ${styles.isMobileBtn}`} id="isMobile" style={{ display: showMobileButton ? 'block' : 'none' }}>Subscribe to SheetKraft's Newsletter</button>
            
            {show &&
                <form className={`form-horizontal col-sm-12 col-md-12 col-lg-10 ${styles.form} `} id="subscribeForm" onSubmit={submitForm}>
                    <div className="form-group">
                        <div className="col-lg-12" style={subscribeStyles}>
                            Subscribe to SheetKraft's Newsletter for latest blog and company updates
                                <span className={styles.close} onClick={closeModal} id="closeModal">&times;</span>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-lg-12">
                            <input type="email" className={` ${styles.email} form-control`} id="email" placeholder="Your email address" name="email" required />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-lg-12 text-center" >
                            <input type="submit" className={`btn btn-primary ${styles.btnSubmit}`} value="Subscribe" />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-sm-offset-2 col-lg-12">
                            <p id="message"></p>
                        </div>
                    </div>
                </form>

            }


        </>

    )
}
