import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import '../styles/main.css'
import styles from './announcements.module.css';

const query = graphql`
query AnnouncementsQuery {
  allIndexYaml(limit: 3) {
    edges {
      node {
        version        
        id
        published(formatString: "MMMM DD , YYYY")
        other
        tags
        category

      }
    }
  }
}
`;

export default function Announcements() {

  const data = useStaticQuery(query);

  return (
    <div className={styles.announcements}>
      <h4 id="announcement" className={styles.announcement}>
        Announcements
      </h4>

      {
        data.allIndexYaml.edges.map(({ node }) => (

          <div key={node.id} className={styles.slug} >
            <Link to={'/blog/' + node.version+'/'} className={styles.Link} >
              <p className={styles.frontmatterTitle} >
                {'Version ' + node.version + ' has been released'}
              </p>
            </Link>
            <p className={styles.frontmatterPublished} >
              Posted on {node.published}
            </p>
            <br />
            <hr className={styles.hrStyles} />
          </div>

        ))
      }
      <Link to={"/blog/category/announcements/"} className={`readAllVersions ${styles.read}`}>Read All</Link>
    </div>
  );
}