import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import "../styles/main.css";
import styles from "./TagCloud.module.css";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

const query = graphql`
  query TagsQuery {
    allMarkdownRemark(
      filter: {
        frontmatter: { draft: { ne: true }, tags: { ne: "Releases" } }
        fields: { slug: { regex: "^/blog/" } }
      }
    ) {
      edges {
        node {
          frontmatter {
            tags
            category
          }
        }
      }
    }
    allIndexYaml {
      edges {
        node {
          version
          id
          published
          other
          tags
          category
          files
        }
      }
    }
  }
`;

export default function TagCloud({ currentTag, basePath }) {

  
  /** Accordion **/
  const [isOpen, setIsOpen] = useState(true);
  const [isUp, setIsPlus] = useState(false);
  const [isDown, setIsMinus] = useState(false);
  const handleClick = (eventKey) => {
    setIsPlus((isUp) => !isUp);
    setIsMinus((isDown) => !isDown);
    if (eventKey === 0) {
      setIsOpen((isOpen) => isOpen);
    }
  };

  const chevronUpIcon = (
    <i className="fa fa-chevron-down" style={{ color: "#000" }}></i>
  );
  const chevronDownIcon = (
    <i className="fa fa-chevron-up" style={{ color: "#000" }}></i>
  );
  /** Accordion **/

  const data = useStaticQuery(query);

  const categoryCaseStudy = data.allMarkdownRemark.edges
    .filter((e) => e.node.frontmatter.category === "case-studies")
    .map((e) => e.node.frontmatter.tags);

  let caseStudyTotalTags = categoryCaseStudy
    .reduce((a, b) => a.concat(b), [])
    .sort(); // for case study category and tags based on the case study category

  let totalTags = [],
    totalCaseStudyTags = [];

  //for md
  let tagCounts = {};
  data.allMarkdownRemark.edges.forEach(({ node }) => {
    node.frontmatter.tags.forEach((t) => {
      tagCounts[t] = (tagCounts[t] || 0) + 1;
    });
  });
  let tags = Object.keys(tagCounts);

  //for releases
  let tagCountsReleases = {};
  data.allIndexYaml.edges.forEach(({ node }) => {
    tagCountsReleases[node.tags] = (tagCountsReleases[node.tags] || 0) + 1;
  });
  let tagsReleases = Object.keys(tagCountsReleases);

  //for caseStudyTags
  let tagCaseStudyCount = {};
  caseStudyTotalTags.forEach((t) => {
    tagCaseStudyCount[t] = (tagCaseStudyCount[t] || 0) + 1;
  });

  caseStudyTotalTags = Object.keys(tagCaseStudyCount);

  totalTags = [...tags, ...tagsReleases].sort();
  totalCaseStudyTags = [...caseStudyTotalTags].sort();

  //console.log(basePath, basePath.indexOf(totalCaseStudyTags) > -1,totalCaseStudyTags);
  
  let splitBasePath = basePath.split("/");
  let splitBasePathResult = splitBasePath[splitBasePath.length - 2]; 
  // console.log(totalCaseStudyTags.indexOf(answer) > -1);


  // console.log(
  //   tagCaseStudyCount,
  //   tagCaseStudyCount,
  //   tagCountsReleases,
  //   tagCounts
  // );

  return (
    <div className={styles.wrapper}>
      <Accordion className={styles.accordion} defaultActiveKey="0" style={{marginTop:basePath.indexOf('announcement')>-1?'0px':'40px'}}>
        <Card>
          <Card.Header>
            <h4 id="tags" className={styles.tags} >
              Tags &nbsp;{" "}
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className={styles.btnLink}
                onClick={() => {
                  handleClick(0);
                }}
              >
                <span className={isUp ? "hidden" : ""}>{chevronUpIcon}</span>
                <span className={isDown ? "" : "hidden"}>
                  {chevronDownIcon}
                </span>
              </Accordion.Toggle>
            </h4>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body className={styles.cardBody}>
              {basePath === "/blog/category/case-studies/" //for case study category
                ? totalCaseStudyTags.map((tag) => (
                    <Link
                      to={"/blog/tag/" + tag + '/'}
                      key={tag}
                      className={currentTag === tag ? "tagHighlight" : "tags"}
                      id="tagStyles"
                    >
                      {tag}
                      <span
                        className="badge badge-pill tagBadges"
                        style={{
                          display: tagCounts[tag] > 1 ? "inline-block" : "none",
                        }}
                      >
                        {tagCounts[tag]}
                      </span>
                    </Link>
                  ))
                : totalCaseStudyTags.indexOf(splitBasePathResult) > -1 //for case study and tag from case study category
                ? totalCaseStudyTags.map((tag) => (
                    <Link
                      to={"/blog/tag/" + tag + '/'}
                      key={tag}
                      className={currentTag === tag ? "tagHighlight" : "tags"}
                      id="tagStyles"
                    >
                      {tag}
                      <span
                        className="badge badge-pill tagBadges"
                        style={{
                          display: tagCounts[tag] > 1 ? "inline-block" : "none",
                        }}
                      >
                        {tagCounts[tag]}
                      </span>
                    </Link>
                  ))
                : totalTags.map((
                    tag //for all tags in blog
                  ) => (
                    <Link
                      to={"/blog/tag/" + tag + '/'}
                      key={tag}
                      className={currentTag === tag ? "tagHighlight" : "tags"}
                      id="tagStyles"
                    >
                      {tag}
                      <span
                        className="badge badge-pill  tagBadges"
                        style={{
                          display:
                            tag === "Releases" || tagCounts[tag] > 1
                              ? "inline-block"
                              : "none",
                        }}
                      >
                        {tag === "Releases"
                          ? tagCountsReleases[tag]
                          : tagCounts[tag]}
                      </span>
                    </Link>
                  ))}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}
